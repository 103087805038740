import { gql } from "graphql-tag";

import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

export const textAlternateGrid = gql`
  fragment Static_page_text_alternate_grid on Static_pageBodyText_alternate_grid {
    type
    label
    primary {
      bg_color
    }
    fields {
      optional_icon
      subtitle
      paragraph
      responsive_icon
      options_selector
      optional_button_label
      optional_button_link {
        ...documentLink
        ...externalLink
      }
    }
  }
  ${documentLink}
  ${externalLink}
`;
