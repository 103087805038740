import { gql } from "graphql-tag";

export const smallText = gql`
  fragment Static_page_small_text on Static_pageBodySmall_text {
    type
    primary {
      text
    }
  }
`;
