import { gql } from "graphql-tag";

import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

export const mattressBrandSection = gql`
  fragment Static_page_mattress_brand_section on Static_pageBodyMattress_brand_section {
    type
    label
    primary {
      optional_icon
      subtitle
      paragraph
      image
      video
      media_alignment
      button_label
      button_link {
        ...documentLink
        ...externalLink
      }
    }
    fields {
      icon
    }
  }
  ${documentLink}
  ${externalLink}
`;
