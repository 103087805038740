import { gql } from "graphql-tag";

import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

export const buttonGallery = gql`
  fragment Static_page_button_gallery on Static_pageBodyButton_gallery {
    type
    fields {
      button_label
      button_link {
        ...documentLink
        ...externalLink
      }
      button_color
    }
  }
  ${documentLink}
  ${externalLink}
`;
