import { gql } from "graphql-tag";
import { externalLink, documentLink } from "~/app/prismic/prismic-fragments";

export const getImageSection = pageType => gql`
  fragment ${pageType}_image_section on ${pageType}BodyImage_section {
     type
     label
     primary {
       image
       image_link {
         ...externalLink
         ...documentLink
       }
    }
 }
 ${externalLink}
 ${documentLink}
`;
