import { gql } from "graphql-tag";

export const scriptSection = gql`
  fragment Static_page_script_section on Static_pageBodyScript_section {
    type
    primary {
      id
      src
    }
  }
`;
