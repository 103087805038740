import { gql } from "graphql-tag";

export const getHtmlSection = pageType => gql`
  fragment ${pageType}_html_section on ${pageType}BodyHtml_section {
     type
     label
     primary {
      html
      src
      type
     }
}`;
