import { gql } from "graphql-tag";

import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

export const mediaAlternateGrid = gql`
  fragment Static_page_media_alterntae_grid on Static_pageBodyMedia_alternate_grid {
    type
    label
    primary {
      heading
      paragraph
    }
    fields {
      video
      image
      internal_video {
        ...externalLink
        ... on _FileLink {
          name
          url
        }
      }
      optional_subtitle
      autoplay
      link {
        ...documentLink
        ...externalLink
      }
    }
  }
  ${documentLink}
  ${externalLink}
`;
