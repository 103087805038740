import { gql } from "graphql-tag";

import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

export const bundleProductsV2 = gql`
  fragment Static_page_bundle_products_v2 on Static_pageBodyBundle_products_v2 {
    type
    label
    variation {
      ... on Static_pageBodyBundle_products_v2Default {
        items {
          image
          subtitle
          paragraph
          options_selector
          button_label
          button_link {
            ...documentLink
            ...externalLink
          }
        }
      }
    }
  }
  ${documentLink}
  ${externalLink}
`;
