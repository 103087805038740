import { gql } from "graphql-tag";

export const iconGallery = gql`
  fragment Static_page_icon_gallery on Static_pageBodyIcon_gallery {
    type
    label
    fields {
      icon
      icon_link {
        ... on _ExternalLink {
          url
          target
        }
      }
    }
  }
`;
