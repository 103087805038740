import { gql } from "graphql-tag";

import { documentLink } from "~/app/prismic/prismic-fragments";

export const alternateGrid = gql`
  fragment Static_page_alternate_grid on Static_pageBodyAlternate_grid {
    type
    label
    fields {
      optional_icon
      item_title
      item_description
    }
    primary {
      image_align
      optional_icon
      subtitle
      paragraph
      image
      video
      media_alignment
      button_label
      optional_button_color
      button_link {
        ...documentLink
        ... on _ExternalLink {
          url
          target
        }
      }
    }
  }
  ${documentLink}
`;
