import { gql } from "graphql-tag";

import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

export const sliderAlternateGrid = gql`
  fragment Static_page_slider_alternate_grid on Static_pageBodySlider_alternate_grid {
    type
    label
    primary {
      optional_icon
      subtitle
      paragraph
      button_label
      button_link {
        _linkType
        ...documentLink
        ...externalLink
      }
      media_alignment
    }
    fields {
      image
    }
  }
  ${documentLink}
  ${externalLink}
`;
