import { gql } from "graphql-tag";

import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

export const callToActionBanner = gql`
  fragment Static_page_call_to_action_banner on Static_pageBodyCall_to_action_banner {
    type
    label
    primary {
      optional_image
      subtitle
      paragraph
      text_color
      bgColor
      optional_call
      optional_email
      button_label
      button_link {
        ...documentLink
        ...externalLink
      }
    }
    fields {
      icon_name
      optional_link {
        ...externalLink
      }
      icon_color
    }
  }
  ${documentLink}
  ${externalLink}
`;
