import { gql } from "graphql-tag";

import { getText } from "~/slices/Text/fragment";
import { getTextBlock } from "~/slices/TextBlock/fragment";
import { getProductCatalogueV2 } from "~/slices/ProductCatalogueV2/fragment";
import { getImageSection } from "~/slices/ImageSection/fragment";
import { getHtmlSection } from "~/slices/HtmlSection/fragment";
import { mattressBrandSection } from "~/slices/MattressBrandSection/fragment";
import { sliderAlternateGrid } from "~/slices/SliderAlternateGrid/fragment";
import { callToActionBanner } from "~/slices/CallToActionBanner/fragment";
import { iconGallery } from "~/slices/IconGallery/fragment";
import { smallText } from "~/slices/SmallText/fragment";
import { scriptSection } from "~/slices/ScriptSection/fragment";
import { textAlternateGrid } from "~/slices/TextAlternateGrid/fragment";
import { buttonGallery } from "~/slices/ButtonGallery/fragment";
import { mediaAlternateGrid } from "~/slices/MediaAlternateGrid/fragment";
import { alternateGrid } from "~/slices/AlternateGrid/fragment";
import { accordian } from "~/slices/Accordian/fragment";
import { feedbackForm } from "~/slices/FeedbackForm/fragment";
import { getYotpoWidget } from "~/slices/YotpoWidget/fragment";
import { getSeoFields, getMetaFields } from "~/mixins/seoMixin";
import { cardMediaGridV2 } from "~/slices/CardMediaGridV2/fragment";
import { bundleProductsV2 } from "~/slices/BundleProductsV2/fragment";

const PAGE_TYPE = "Static_page";

const QUERY_PAGES = gql`
  query static_page($uid: String!, $lang: String!, $lazy: Boolean = false) {
    ${PAGE_TYPE}:static_page(uid: $uid, lang: $lang) {
          title
          description
          ...Static_pageSEOFields
          ...Static_pageMetaFields
          slices: body {
            ...${PAGE_TYPE}_image_section
            ...${PAGE_TYPE}_script_section
            ...${PAGE_TYPE}_html_section
            ...${PAGE_TYPE}_small_text
            ...${PAGE_TYPE}_mattress_brand_section
            ...${PAGE_TYPE}_text_alternate_grid
            ...${PAGE_TYPE}_text
            ...${PAGE_TYPE}_button_gallery
            ...${PAGE_TYPE}_icon_gallery
            ...${PAGE_TYPE}_media_alterntae_grid
            ...${PAGE_TYPE}_product_catalogue_v2
            ...${PAGE_TYPE}_text_block
            ...${PAGE_TYPE}_call_to_action_banner
            ...${PAGE_TYPE}_slider_alternate_grid
            ...${PAGE_TYPE}_alternate_grid
            ...${PAGE_TYPE}_accordian
            ...${PAGE_TYPE}_feedback_form
            ...${PAGE_TYPE}_yotpo_widget
            ...${PAGE_TYPE}_card_media_grid_v2
            ...${PAGE_TYPE}_bundle_products_v2
          }
        }
      }
  ${getText(PAGE_TYPE)}
  ${getTextBlock(PAGE_TYPE)}
  ${getProductCatalogueV2(PAGE_TYPE)}
  ${getSeoFields(PAGE_TYPE)}
  ${getMetaFields(PAGE_TYPE)}
  ${getImageSection(PAGE_TYPE)}
  ${getHtmlSection(PAGE_TYPE)}
  ${getYotpoWidget(PAGE_TYPE)}
  ${mattressBrandSection}
  ${sliderAlternateGrid}
  ${callToActionBanner}
  ${iconGallery}
  ${smallText}
  ${scriptSection}
  ${textAlternateGrid}
  ${buttonGallery}
  ${mediaAlternateGrid}
  ${alternateGrid}
  ${accordian}
  ${bundleProductsV2}
  ${feedbackForm(PAGE_TYPE)}
  ${cardMediaGridV2(PAGE_TYPE)}
`;

export { PAGE_TYPE, QUERY_PAGES };
