import { gql } from "graphql-tag";

export const accordian = gql`
  fragment Static_page_accordian on Static_pageBodyAccordian {
    type
    label
    primary {
      accordian_title
    }
    fields {
      optional_icon
      item_title
      accordDesc: item_description
    }
  }
`;
