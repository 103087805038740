import { gql } from "graphql-tag";

import { documentLink, externalLink } from "~/app/prismic/prismic-fragments";

export const cardMediaGridV2 = pageType => gql`
  fragment ${pageType}_card_media_grid_v2 on ${pageType}BodyCard_media_grid_v2  {
    type
    label
    variation {
      variation: __typename
      ... on ${pageType}BodyCard_media_grid_v2Default {
        primary {
          title
          description
        }
        items {
          subtitle
          paragraph
          image
        }
      }
      ... on ${pageType}BodyCard_media_grid_v2Cardmediagridwithlink {
        primary {
          title
          description
        }
        items {
          subtitle
          paragraph
          image
          button_link {
              ...documentLink
              ...externalLink
          }
          button_label
        }
      }
    }
  }
  ${documentLink}
  ${externalLink}
`;
