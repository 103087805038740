import { gql } from "graphql-tag";

export const getYotpoWidget = pageType => gql`
  fragment ${pageType}_yotpo_widget on ${pageType}BodyYotpo_widget {
    type
    label
    primary{
        sub_title
        widget_html
        script_source
    }
}`;
